@import "login";
@import "builder";
@import "browser";
@import "page-title";
@import "select-block";
@import "select-table";
@import "accomodal";
@import "pdf";
@import "register";
@import "topbar";
@import "dash-messages";

.mainBody {
  overflow: auto;
  position: relative;
  width: 100vw;
  height: 100vh;
  @include transition(all 0.3s ease);
}



/** DASHBOARD *****************************************************************************************/

.welcome {
  margin-bottom: 50px;

  p {
    @include rem-size(1.25);

    @media (max-width: 650px) {
      @include rem-size(1);
    }
  }
}

.getstarted {
}

.acco-functions {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  padding-top: 25px;
  border-top: solid 1px #CCC;
}


/** MISCELLANEOUS *****************************************************************************************/
.card, .sidebar {
  background-color: rgba(255,255,255,0.65) !important;
}
.card-body {
  background-color: transparent !important;
}

.collapsing {
  position: absolute !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.table .btn {
  margin-bottom: 0;
  padding: 0px 14px;
}

.table.no-top-border th {
  border-top: 0;
  padding: 10px !important;
}

.btn.btn-pill {
  border-radius: 50px !important;
  font-weight: bold !important;

  .fa-lg {
    position: relative;
    top: 2px;
  }
}

.btn-lg {
  padding: 8px 40px !important;
  font-size: 20px !important;
}


input, select, textarea, button, .form-control {
  &:active, &:focus {
    outline: none !important;
  }
}
.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.card-header {
  font-weight: 700;
}

p.form-error {
  @include rem-size(0.9);
  margin-top: 5px;
}

.form-group label {
  font-weight: 500;
  margin-bottom: 1px;

  span {
    color: #777;
    @include rem-size(0.85);
  }
}

.form-textarea {
  height: 250px !important;
}
.form-image {
  display: block;
  max-width: 100%;
}

.green {
  color: $color-green;
}
.blue {
  color: $color-blue;
}

.alert-success {
  color: #77931F !important;
  background-color: lighten($color-green,45%) !important;
  border-color: $color-green !important;
}

.alert-error {
  color: #BB0000 !important;
  .btn-danger {
    margin-top: 10px;
  }
}



.search-bar-div {
  position: relative;
  overflow: auto;
  clear: both;
  height: 80px;

  .fas {
    position: absolute;
    top: 14px;
    left: 12px;
    transform: rotate(90deg);
    @include rem-size(1.7);
  }
}

.search-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 7px 7px 7px 55px;
  border-radius: 100px;
  border: solid 2px $color-light-gray;
  @include rem-size(1.7);
}

.gears {
  width: 200px;
  height: 185px;
  background: $color-accent-hover;
  text-align: center;
  line-height: 185px;
  margin: 50px auto;
  border-radius: 40px;
}

.centered {
  text-align: center;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: #F7F7F7;
  text-align: center;
  @include rem-size(0.85);

  &-info {
    display: none;
  }

  &:hover {
    .footer-info {
      display: block;
    }
  }
}

.clearSearch {
  float: right;
  color: $color-blue;
  cursor: pointer;

  &:hover {
    color: $color-green;
  }
}

.form-control.stripe {
  padding-top: 9px;
}


.accoAccess {
  background-color: lighten($color-green, 41%);
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 12px;
  text-align: center;
}


.form-group {
  margin-bottom: 16px;
}

a.btn-success, .btn-success {
  background-color: $color-green !important;
  color: $white !important;
  border-color: $color-green !important;

  &:hover, &:active, &:focus {
    background-color: darken($color-green,10%) !important;
    color: $white !important;
    border-color: darken($color-green,10%) !important;
  }
}

a.btn-primary, .btn-primary {
  background-color: $color-blue !important;
  color: $white !important;
  border-color: $color-blue !important;

  &:hover, &:active, &:focus {
    background-color: darken($color-blue,10%) !important;
    color: $white !important;
    border-color: darken($color-blue,10%) !important;
  }
}

a.btn-danger, .btn-danger {
  background-color: $color-red !important;
  color: $white !important;
  border-color: $color-red !important;

  &:hover, &:active, &:focus {
    background-color: darken($color-red,10%) !important;
    color: $white !important;
    border-color: darken($color-red,10%) !important;
  }
}

.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
