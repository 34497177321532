
.heading-button {
    padding: 3px 20px !important;
}
.change-status {
    margin-bottom: 20px; 
    @include shadow();

    &.editing {
        background-color: $color-accent;
        color: $white;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        padding: 6px;
    }
    &.review{
        text-align: center;
        border: none;
    }
    &.review > h3{
        background-color: $color-red;
        color: $white;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        padding: 6px;
    }

    &.review > .btn-success, .btn-danger {
        color: white;
    }
}

.eco-heading {
    display: flex;
    margin-bottom: 16px;
    @include rem-size(1.25);

    div {
        width: 33.3%;
    }
}


.container {
    width: auto;
    max-width: 1125px !important;
}


.important {
    background-color: #F7EDDC;
}

.card {
    @include shadow();
}

@include links($color-green, darken($color-green,10%), none, underline);

.btn.btn-primary {
    color: $white !important;
    background-color: $color-accent !important;;
    border-color: $color-accent !important;
    text-decoration: none !important;
    @include transition(all .1s ease);
}
.btn.btn-primary:disabled, .btn.btn-primary.disabled {
    color: $white !important;
    background-color: $color-accent !important;;
    border-color: $color-accent !important;
    text-decoration: none !important;
    @include transition(all .1s ease);
    opacity: 0.5;
}
.btn:hover {
    color: $white !important;
    text-decoration: none !important;
}


.dashboard.container {
    padding-bottom: 100px;
}

.btn-inline {
    font-size: 1em !important;
    padding: 0px 7px !important;
    display: inline-block !important;
    margin: 0 !important;
}

.red {
    color: $color-red !important;
}
.green {
    color: $color-green !important;
}

.btn.no-hover:before {
    background-color: transparent !important;
}

input {
    &.input-small {
        width: 100px;
        text-align: center;
    }
    &.input-medium {
        width: 250px;
    }
    &.input-large {
        width: 400px;
    }
}

.dropdown {
    display: inline-block !important;
    text-align: left;
}

.padding-10 {
    padding: 10px;
}

.table-scroll {
    overflow-x: scroll;
}
table.RPMTable.table.table-striped  {

    thead {
        tr th {
            border: solid 1px #EEE;
            border-top: solid 2px #CCC;
            border-bottom: solid 2px #CCC;
        }
        tr:first-child th {
            background-color: #F2F2F2;
        }
        tr:last-child th {
            background-color: #FFFFFF;
        }
    }

    tbody tr:nth-of-type(even) {
        background-color: #FFFFFF;
    }
    tbody tr:nth-of-type(odd) {
        background-color: #F2F2F2;
    }
    tbody tr:hover {
        background-color: #ffeed7 !important;
    }
    tbody tr td {
        white-space: nowrap;
        border: solid 1px #EEE;
    }

    input.RPMTable-filter {
        border-color: #DDDDDD;
        padding: 0 4px 0 20px !important;
        font-size: 14px;
        min-width: 50px;
        background: url(../shared/img/filter-input-icon.png) 4px center no-repeat;
    }
}

.border-top {
    border-top: 1px solid #dee2e6;
    padding: 20px 0;
}

.quill {
    background-color: #FFF;
    border-radius: 4px;
}
.quill .ql-toolbar {
    border-radius: 4px 4px 0 0;
    background-color: #F4F4F4;
}
.ql-container {
    font-size: 15px !important;
}


.loader {
    width: 100%;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin-bottom: 30px;
    background-color: rgba(255, 255, 255, 0.65) !important;
    @include shadow();

    &-full {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        text-align: center;
        background-color: #EEEEEE;
        height: 100vh;
        line-height: 100vh;
    }

    .fa-hurricane {
        font-size: 50px;
        color: $color-red;
    }
}


/*******************************************************************************************/
.auth-form .form-group {
    label {
        @include rem-size(1);
        margin-bottom: 4px;
        display: block;
        overflow: auto;

        span {
            float:right;
            color: $color-red;
            @include rem-size(0.8);
            font-style: italic;
            position: relative;
            top: 3px;

            &.gray {
                color: #999;
            }
        }

        &.req:before {
            content: "* ";
            color: $color-red;
        }
    }
    .auth-form-group {
        display: block;
        overflow: auto;
        clear: both;
    }
    .form-control.input-ico-left.input-ico-right {
        width: calc(100% - 84px);
        border-radius: 0;
        border-style: solid !important;
        border-color: #DDDDDD !important;
        border-width: 1px 0 !important;
        float: left;
    }
    .form-control.input-ico-left {
        width: calc(100% - 42px);
        border-radius: 0 0.25rem 0.25rem 0;
        border-style: solid !important;
        border-color: #DDDDDD !important;
        border-width: 1px 1px 1px 0 !important;
        float: left;
    }
    .form-control.input-ico-right {
        width: calc(100% - 42px);
        border-radius: 0.25rem 0 0 0.25rem;
        border-style: solid !important;
        border-color: #DDDDDD !important;
        border-width: 1px 0 1px 1px !important;
        float: left;
    }
    .ico-right {
        width: 42px;
        height: 38px;
        text-align: center;
        border-radius: 0 0.25rem 0.25rem 0;
        background-color: #DDDDDD;
        color: $color-blue;
        float: left;
        line-height: 38px;
        @include rem-size(1.1);

        i.fas {
            cursor: pointer;
        }
    }
    .ico-left {
        width: 42px;
        height: 38px;
        text-align: center;
        border-radius: 0.25rem 0 0 0.25rem;
        background-color: #DDDDDD;
        color: $color-blue;
        float: left;
        line-height: 38px;
        @include rem-size(1.1);
    }

    .aboutme {
        height: 120px;
    }

    &.saving-button {
        text-align: center;
        i {
            @include rem-size(1.75);
            color: $color-red;
            position: relative;
            top: -3px;
            visibility: hidden;

            &.fa-hurricane {
                color: $color-red;
            }
            &.fa-check-circle {
                color: $color-green;
            }

            &.active {
                visibility: visible;
            }
        }
    }
}

.card-error {
    color: $color-red;
    @include rem-size(0.8);
    font-style: italic;
    font-weight: bold;
    position: relative;
    top: 3px;
}

.form-group .image-upload {
    overflow: auto;
    clear: both;
    @include rem-size(0.8);

    input {
        width: calc(100% - 29px);
        @extend .form-control;
        margin-bottom: 5px;
        float: left;
    }
    button {
        width: 24px;
        height: 24px;
        padding: 0;
        margin: 0 0 0 5px;
        line-height: 24px;
        color: $white;
        text-align: center;
    }
    .form-image {
        cursor: pointer;
    }
}

.image-preview {
    position: relative;

    .img-uploading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        line-height: 100%;
        background-color: rgba(255,255,255,.75);
        background-image: url(../shared/img/meeteor-anim-2.svg);
        background-size: 75px 75px;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.justhideit {
    display: none !important;
}
.invisible {
    visibility: hidden;
}
.pag-opacity {
    opacity: 0.4;
}

/*******************************************************************************************/
.community-admins {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 40px;

    .community-admin {
        min-width: 130px;
        max-width: 200px;
        border: solid 1px #CCCCCC;
        padding: 10px;
        background-color: $white;
        text-align: center;
        border-radius: 12px;
        margin: 10px 5px;

        img {
            width: 80px;
            height: 80px;
            display: block;
            margin: 0 auto 10px;
        }
        div {
            margin-top: 10px;
        }

        .fa-map-marker-alt {
            display: block;
            text-align: center;
            @include rem-size(3);
            margin: 5px 0 10px;
            color: $color-red;
        }
    }

    &.small {
        .community-admin {
            cursor: pointer;
            min-width: 100px;
            @include rem-size(0.8);

            &:hover {
                border-color: $color-red;
            }

            img {
                width: 60px;
                height: 60px;
            }
        }
    }
}

.admin-search {
    //margin-top: 30px;
    label {
        font-weight: bold;
        margin: 0;
        padding: 0;
    }
    span {
        color: #888;
        font-style: italic;
        font-weight: normal;
        @include rem-size(0.9);
    }
}

.card-header span {
    color: #666;
    font-weight: normal;
    @include rem-size(0.85);
}
.card-info {
    border-left: solid 4px $color-red;
    padding: 8px 15px;
    background-color: rgba(255,255,255,0.75);
    margin-bottom: 30px;
}

.alert-bottom-margin {
    margin-bottom: 40px !important;
}

td.center-cell, th.center-cell {
    text-align: center;
}

.tooltip.show {
    opacity: 1 !important;
}

.alert__icon i {
    line-height: 1.5;
    vertical-align: middle;
    @include rem-size(1.5);
}

.tableMetrics {
    margin-bottom: 10px;
}

.no-connection-logo {
    width: 300px;
    margin-right: 30px;

    .cls-1{fill:#808285;}
    .cls-2{fill:#ed4237;}
}

.place-options {
    padding-top: 6px;
    border-top: solid 1px #CED4DA;
    margin-bottom: 8px;
    @include links(lighten($color-gray, 10%), darken($color-gray,10%), none, none);

    a.on {
        color: $color-red !important;
        font-weight: bold;
    }
}

.place-checkbox {
    display: block;
    overflow: auto;
    margin-bottom: 8px;
    cursor: pointer;

    input {
        float:left;
        margin-right: 5px;
        zoom: 150%;
        position: relative;
        top: 3px;
    }
    &-content {
        float:left;
        width: calc(100% - 35px);

        i {
            color: lighten($color-gray, 10%);
            font-style: normal;;
        }
    }
}

.ql-editor p {
    margin-bottom: 1.2em;
}

h1,h2,h3,h4,h5 {
    font-family: 'atten-round-new', arial, sans-serif !important;
    font-weight: 700 !important;
}
.hXL {
    font-size: 55px;

    @media (max-width: 700px) {
        font-size: 44px;
    }
    @media (max-width: 500px) {
        font-size: 32px;
    }
}
.green {
    color: $color-green !important;
}
.blue {
    color: $color-blue !important;
}


.topbar__right {
    .topbar__profile button.topbar__avatar {
        border-radius: 50px;
        background-color: #FFF;
        border: solid 2px $color-light-gray;
        height: 50px;
        padding-left: 20px;
    }

    .cartbutton {
        position: relative;
        margin-left: 20px;

        .carticon {
            display: inline-block;
            border-radius: 50px;
            background-color: #FFF;
            border: solid 2px $color-light-gray;
            height: 50px;
            width: 50px;
            line-height: 50px;
            text-align: center;
            @include rem-size(1.5);
            color: $colorText;
        }
        .cartbadge {
            position: absolute;
            bottom: 10px;
            right: -5px;
            background-color: $color-blue;
            color: #FFF;
            border-radius: 20px;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            @include rem-size(1);
        }
    }

}

.alert--bordered {
    overflow: auto;
    clear: both;
}
.alert__icon {
    float: left;
    width: 40px;

    &.danger {
        color: $color-red;
    }
    &.warning {
        color: $black;
    }
    &.success {
        color: $color-green;
    }
    &.info {
        color: $color-blue;
    }
}
.alert__content {
    float: left;
    width: calc(100% - 40px);
}
.acco-register .acco-plan .access-code i {

}

.name-caret {
    font-size: 16px;
    line-height: 44px;
    margin: 0 10px;

    @include themify($themes) {
        color: themed('colorText');
    }
}

.alert__icon {

}


/*
https://fonts.google.com/icons?selected=Material+Symbols+Outlined:cancel:FILL@0;wght@400;GRAD@0;opsz@24&icon.size=24&icon.color=%23e8eaed&icon.query=CLOSE
 */
.material-symbols-outlined {
    font-variation-settings:
            'FILL' 0,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24
}