.acco-modal {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  overflow: scroll;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease, z-index 1ms ease 1s;

  &-blur {
    filter: blur(6px);
  }

  &-wrap {
    display: table-cell;
    vertical-align: middle;
    width: 100vw;
    height: 100vh;
  }

  &-body {
    max-height: calc(100vh - 160px);
    margin-top: 200px;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
    position: relative;
    @include shadow(10px, 20px, 0.4);
    @include transition(all 0.3s ease);
  }

  &-scroller {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
  }

  &.large, &.xl {
    .acco-modal-body {
      width: 90%;
      max-width: 1000px;
      min-height: 500px;
    }
  }

  &.small {
    .acco-modal-body {
      width: 90%;
      max-width: 650px;
      min-height: 300px;
    }
  }

  &.open {
    opacity: 1;
    z-index: 102;
    transition: z-index 1ms, opacity 0.3s ease 1ms;

    .acco-modal-body {
      margin-top: 80px;
    }
  }


  &-content {
    padding: 30px 40px 40px;
    @include rem-size(1);
  }
  &-close {
    position: absolute;
    top: -8px;
    right: -10px;
    font-size: 36px;
    border-radius: 18px;
    overflow: visible;
    cursor: pointer;
    width: 35px;
    height: 32px;
    @include shadow(3px, 6px, 0.6);

    .fa-circle {
      color: #FFFFFF;
      position: absolute;
      font-size: 34px;
      top: 1px;
      right: 1px;
    }
    .fa-times-circle {
      color: $color-red;
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
    }
  }
  &-save {
    position: absolute;
    width: 100%;
    display: block;
    bottom: -13px;
    font-size: 36px;
    overflow: visible;
    cursor: pointer;
    height: 32px;
    text-align: center;

    button {
      min-width: 200px;
      border-radius: 50px;
      font-weight: bold;
      @include shadow(3px, 6px, 0.6);
      @include rem-size(1.2);
    }
  }

  .option-list {
    h4 {
      color: $color-blue;
      border-bottom: solid 1px $color-gray;
    }
    .fa-stack {
      width: 1.5em;
      @include rem-size(1.1);

      .fa-check {
        @include rem-size(1.15);
        color: $color-green;
        position: relative;
        top: -3px;
        left: 4px;
      }
    }
  }

  .written-field {
    border-top: solid 1px $color-gray;
    margin: 30px 0;
  }
}

.acco-close-icon {
  position: absolute;
  color: red;
  font-size: 42px;
  font-weight: bold;
  top: -6px;
  left: -4px;
  border-radius: 50%;
  padding: 0;
}