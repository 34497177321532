.pdf {
  &-header {
    color: $color-blue;
    font-weight: 700;
    border-top: solid 6px $color-green;
    text-align: right;
    height: 75px;
    padding-top:8px;
    background-size: 233px 45px;
    background-repeat: no-repeat;
    background-position: left center;
    margin-bottom: 30px;
    line-height: 1.2em;

    @include themify($themes) {
      background-image: themed('logoImg');
    }
  }

  &-body {
    margin: 30px 50px;

    h4 {
      margin-top: 40px;
    }
    p {
      margin-bottom: 1.3em !important;
    }
  }
}

.accomod-list {
  .pdf-body {
    margin: 30px 0;
    padding-bottom: 30px;
    border-bottom: solid 1px $color-light-gray;
  }
}

@media (max-width: 800px) {
  .acco-modal-content {
    padding: 30px 20px 30px;
  }
  .pdf-header {
    margin-bottom: 16px;
  }
  .pdf-body {
    margin: 5px 10px;
  }
}

@media (max-width: 600px) {
  .pdf-header {
    display: none;
  }
  .pdf-body {
    margin: 5px 0;

    h2 {
      @include rem-size(1.7);
    }
  }
  .acco-modal.open .acco-modal-body {
    margin-top: 30px;
  }
  .acco-modal.large .acco-modal-body {
    width: 94%;
    margin-bottom: 40px;
    max-height: calc(100vh - 100px);
  }
  .acco-modal-scroller {
    max-height: calc(100vh - 100px);
  }
}
