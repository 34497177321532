.dash-messages {

  border: solid 1px $color-green;
  background-color: #F5FBE8;
  margin-top: 80px;
  min-height: 100px;
  border-radius: 20px;
  padding: 20px 30px;

  .dash-message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    transition: opacity 0.5s ease-in-out;
    //position: absolute;

    &-icon {
      font-size: 50px;
      line-height: 50px;
      color: $color-green;
      position: relative;
      top: 6px;
    }

    &-body {
      flex: 1;
      margin-left: 30px;

      h1 {
        color: $color-green;
        @include rem-size(2);
        position: relative;
        left: -3px;
        line-height: 2rem;
        margin-bottom: 0.75rem;
      }
    }
  }

  .hidden {
    display: none;
  }

  .fade-in {
    opacity: 1;
  }

  .fade-out {
    opacity: 0;
  }
}

.carousel-dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $colorIcon;
    margin: 0 5px;
    transition: background-color 0.3s;
    cursor: pointer;

    &.active {
      background-color: $color-green;
    }
  }
}

