.acco-browser {
  display: flex;
  flex-direction: row;
  
  .acco-browser-nav {
    padding: 0 16px;
    width: 300px;
    margin-right: 20px;
    
    .sticky {
      top: 110px;
      position: sticky;
      overflow-y: auto;
      max-height: 70vh;
      padding-right: 16px;
    }
  }

  .acco-browser-body {
    width: calc(100% - 320px);
  }

  .acco-browser-select {
    display: none;
    margin-bottom: 24px;
  }

  .browserNav-category {
    display: block;
    color: $color-blue !important;
    font-weight: bold;
    font-size: 18px;
    font-family: 'atten-round-new', arial, sans-serif;
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: solid 1px $color-light-gray;
    cursor: pointer;

    &:hover {
      color: $color-green !important;
    }

  }

  .browserNav-block {
    margin-bottom: 24px;
    display: none;

    &.open {
      display: block;
    }
  }

  .browserNav-subtopic {
    cursor: pointer;
    color: $colorText;
    padding: 6px;

    &:hover {
      color: $color-green;
    }

    &.active {
      background-color: #DAF094;
      font-weight: bold;
    }
  }
}

//Ensure this matches the scrollTo call in BrowserTopics.tsx
@media (max-width: 775px) {
  .acco-browser {
    flex-direction: column;

    .acco-browser-select {
      display: block;
    }
    .acco-browser-nav {
      display: none;
    }
    .acco-browser-body {
      width: 100%;
    }
  }
}