.acco-select-block {
  margin: 40px 0;

  label {
    display: block;
  }
  &-title {
    color: $color-blue;
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: solid 1px $color-light-gray;
  }
  &-title2 {
    color: $color-blue;
    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: solid 1px $color-light-gray;
  }

  &-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    div {
      width: 50%;
      overflow: auto;
      line-height: 1.25em;
      margin-bottom: 20px;
      @include rem-size(1);

      input[type=checkbox] {
        float: left;
        zoom: 160%;
        margin-right: 6px;
      }

      span {
        float: left;
        display: inline-block;
        width: calc( 100% - 50px);
      }

      @media (max-width: 400px) {
        width: 100%;
        display: block;
      }
    }

    @media (max-width: 400px) {
      width: 100%;
      display: block;
    }
  }
}

.priority-high {
  font-weight: bold;
  color: $color-red;
}
.priority-med {
  font-weight: bold;
  color: $color-orange;
}
.priority-low {
  font-weight: bold;
  color: $color-green;
}

.traitClick {
  cursor: pointer;

  &:hover {
    color: $color-green;
  }
}
