.background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center 25%;
  background-repeat: no-repeat;
}

.background-contain {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin rem-size($remValue: 1 ) {
  font-size: ($remValue * $font-size-px-default); //fallback for old browsers
  font-size: $remValue + rem;
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin shadow($vert: 6px, $blur: 12px, $opacity: 0.075) {
  -webkit-box-shadow: 0 $vert $blur 0 rgba(0, 0, 0, $opacity);
  -moz-box-shadow: 0 $vert $blur 0 rgba(0, 0, 0, $opacity);
  box-shadow: 0 $vert $blur 0 rgba(0, 0, 0, $opacity);
}

@mixin placeholder($color: #999999) {
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $color;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $color;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $color;
  }
}

@mixin ratio( $ratio: 50% ) {
  //display: block;
  position: relative;
  //width: 100%;
  margin: 0 auto;
  @extend .background-cover;

  &-inner {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: $ratio;
  }
}

@mixin links( $color: $black, $hover-color: $yellow, $underline: none, $underline-hover: underline ) {
  a, a:link, a:active, a:visited {
    color: $color;
    text-decoration: $underline;
    @include transition(all .1s ease);
  }
  a:hover {
    color: $hover-color !important;
    text-decoration: $underline-hover;
  }
}

@mixin columns( $count: 3, $gap: 40px, $rule: 0px, $style: outset ) {
  /* Chrome, Safari, Opera */
  -webkit-column-count: $count;
  -webkit-column-gap: $gap;
  -webkit-column-rule-style: $style;
  -webkit-column-rule-width: $rule;

  /* Firefox */
  -moz-column-count: $count;
  -moz-column-gap: $gap;
  -moz-column-rule-style: $style;
  -moz-column-rule-width: $rule;

  column-count: $count;
  column-gap: $gap;
  column-rule-style: $style;
  column-rule-width: $rule;
}


.square {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  @extend .background-cover;

  &-inner {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 85%;
  }
}

.rectangle {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  @extend .background-cover;

  &-inner {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 55%;
  }
}
