.cmt-login {
  width: 100vw;
  height: 100vh;
  padding-top: 150px;
  background-color: $white;
  // background: url(../shared/img/wbg.jpg);
  @extend .background-cover;

  &-inner {
    max-width: 1200px;
    margin: auto;
  }

  &__title {
    margin-bottom: 75px;

    .logo {
      display: block;
      width: 400px;
      height: auto;
      margin: 0 auto 20px;

      @media (max-width: 600px) {
        max-width: 325px;
      }
    }
    h3 {
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    padding-top: 75px;
  }

}


.what-is-accomods {
  overflow: auto;
  clear:both;
  margin-bottom: 100px;
  margin-top: 75px;
  padding-top: 25px;
  border-top: solid 1px #CCC;

  div:first-child {
    float:left;
    width:125px;
    margin-right: 20px;

    img {
      max-width: 125px;
    }
  }
  div:last-child {
    float:left;
    width: calc(100% - 145px);
  }

  @media (max-width: 500px) {
    div:first-child {
      float:none;
      width:100%;
      margin-right: 0;

      img {
        max-width: 250px;
        display: block;
        margin: 0 auto 30px;
      }
    }
    div:last-child {
      float:none;
      width:100%;
    }

  }
}

.secure-checkout {
  max-width: 280px;
  margin: 20px 0;
  display: block;
}
