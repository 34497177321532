$themes: (
  light: (
    colorBackground: white,
    colorBackgroundBody: #F2F2F2, //#f2f4f7,
    colorText: #212121,
    colorTextAdditional: #97B92E,
    logoImg: url(../../shared/img/logo.png),
    colorHover: #fbfbfb,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #DDDDDD,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
    navIconColor: #97B92E,
    tableHeadBGColor: #ffd38c,
    tableRowHoverColor: #FFF,
    tableHeadTextColor: #111111,
    tableStripeBGColor: #F6F6F6,
    colorAccent: #97B92E,
    colorPageTitle: #3E787D,
  )
);

$font-size-px-default:  14px;

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$white: #FFFFFF;
$black: #000000;

$color-accent: #3e787d;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #3E787D;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #e20307;
$color-red-hover: darken($color-red, 10%);

$color-orange: #E87400;
$color-orange-hover: darken($color-orange, 10%);

$color-blue: #3E787D;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$color-light-gray: #d8dfe9;

$color-green: #97B92E;
$color-green-hover: darken($color-green, 10%);



$colorBackground: white;
$colorBackgroundBody: #FFF; //#f2f4f7;
$colorText: #212121;
$colorTextAdditional: #97B92E;
$logoImg: url(../../shared/img/logo.png);
$colorHover: #fbfbfb;
$colorBorder: #eff1f5;
$colorIcon: #dddddd;
$imgInvert: invert(0%);
$colorFieldsBorder: #DDDDDD;
$colorBubble: rgba(242, 244, 247, 0.65);
$colorBubbleActive: rgba(234, 238, 255, 0.6);
$colorScrollbar: #B4BFD0;
$colorFitness: #646777;
$navIconColor: #97B92E;
$tableHeadBGColor: #ffd38c;
$tableRowHoverColor: #FFF;
$tableHeadTextColor: #111111;
$tableStripeBGColor: #F6F6F6;
$colorAccent: #97B92E;
$colorPageTitle: #3E787D;
