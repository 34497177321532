.acco-page-title {
  padding-bottom: 10px;
  margin-bottom: 40px;
  border-bottom: solid 1px $color-light-gray;

  &-links {
    text-align: right;
    float: right;
    width: 50%;

    @media (max-width: 650px) {
      width: 100%;
      float: none;
      text-align: center;
      zoom: 85%;
    }
  }

  &-text {
    float: left;
    width: 100%;

    @media (max-width: 650px) {
      width: 100%;
      float: none;
      text-align: center;
    }
  }

  &-link {
    margin-left: 30px;
    position: relative;
    top: 10px;

    &:first-child {
      margin-left: 0;
    }

    a {
      font-weight: bold;
      @include rem-size(1.3);
    }

    i {
      color: $color-green;
    }

    @media (max-width: 650px) {
      top: 0;
    }
  }


  p.half {
    width: 55%;
  }
  p.full {
    width: 90%;
  }

}
