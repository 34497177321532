header#page-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  z-index: 101;
  background-color: #F7F7F7;
  //@include shadow( 3px, 6px );
  border-bottom: 1px solid #c4c4c4;

  img {
    max-height: 50px;
  }

  .action-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .button {
      position: relative;
      border-radius: 50px;
      background-color: #fff;
      border: 2px solid #d8dfe9;
      height: 50px;
      min-width: 50px;
      padding: 20px;
      cursor: pointer;
      margin-left: 10px;

      &.flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 400;

        i.fa-caret-down {
          margin-left: 20px;
          font-size: 18px;
        }
        i.fa-file {
          margin-right: 10px;
          font-size: 20px;
          position: relative;
          top: 2px;
        }
      }

      .cartbadge {
        background-color: $color-blue;
        color: $white;
        width: 26px;
        height: 26px;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        border-radius: 20px;
        margin-right: 7px;
      }
    }
  }

  .topbar__menu-wrap {
    position: absolute;
    width: 100%;
    min-width: 245px;
    left: 0;
    top: 50px;
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #d8dfe9;

    .topbar__menu {
      width: 236px;
      border-radius: 0;
      border: none;
      padding: 5px 0;
      box-shadow: 0 2px 15px 0 rgb(0 0 0 / 5%);
      margin-top: 0;
    }

    .topbar__link {
      display: block;
      color: #212121;
      padding: 8px 0 8px 20px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        background: #3e787d;
        opacity: 0;
        transition: all .3s;
      }
    }
    .topbar__link-icon {
      color: #ddd;
    }

    .topbar__menu-divider {
      border-top: 1px solid #eff1f5;
    }
  }

}
